var scrollAnchors = {};

(function(scrollAnchors) {

  scrollAnchors.scrollTo = function(anchor) {
    $.scrollTo($(anchor), 1000, { offset: -110 });
  };

  scrollAnchors.click = function($anchor) {
    $anchor.click(function(e) {
      e.preventDefault();

      var href = $(this).attr('href');

      scrollAnchors.scrollTo(href);
    });
  };

  scrollAnchors.init = function() {
    scrollAnchors.click($('.scroll-anchor'));
  };

})(scrollAnchors);
